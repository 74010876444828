<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>AI판별이력</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body">
				<form @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<!-- 기간 -->
						<div class="date">
							<div class="form-group">
								<input type="date" class="form-control" v-model="searchVM.dtm" required />
							</div>
						</div>
						<!-- 시간대 -->
						<div class="time">
							<div class="form-group">
								<input type="time" class="form-control" v-model="searchVM.timeStat" required />
								&nbsp;~&nbsp;
								<input type="time" class="form-control" v-model="searchVM.timeEnd" :min="searchVM.timeStat" required />
							</div>
						</div>
						<!-- CCTV -->
						<div class="cctv">
							<div class="form-group" v-if="cctvChoiceMethod == 'cctvSelect'">
								<label>{{ detailsFieldMap.searchCctv }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.cctvInstallPlaceContSelect">
										<option value="">전체</option>
										<option v-for="item in options.cctvOptions" :key="item.cctvInstallPlaceCont" :value="item.cctvInstallPlaceCont">
											{{ item.cctvInstallPlaceCont }}
										</option>
									</select2>
								</div>
							</div>
							<div class="form-group" v-if="cctvChoiceMethod == 'cctvText'">
								<label>{{ detailsFieldMap.searchCctv }}</label>
								<input type="text" class="form-control" :placeholder="detailsFieldMap.searchCctv" v-model="searchVM.cctvInstallPlaceContText" />
							</div>
						</div>
						<div class="cctv-options">
							<div class="form-group">
								<label style="margin-top: 8px">이력</label>
								<div class="checkbox">
									<input type="checkbox" id="check" v-model="searchVM.isCheckedCctvAll" />
								</div>
							</div>
							<div>
								<span>
									(CCTV 검색어 입력 방법)
									<div>
										<input type="radio" v-model="cctvChoiceMethod" name="cctvSelect" value="cctvSelect" checked />
										<label for="cctvSelect">선택</label>
										<input type="radio" v-model="cctvChoiceMethod" name="cctvText" value="cctvText" />
										<label for="cctvText">텍스트</label>
									</div>
								</span>
							</div>
						</div>
					</div>
					<div class="row">
						<!-- 현장 -->
						<div>
							<div class="form-group">
								<label>{{ detailsFieldMap.searchSite }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.siteId" :disabled="isDisabledSearchSite">
										<option value="">전체</option>
										<option v-for="item in options.siteOptions" :key="item.siteId" :value="item.siteId">
											{{ item.siteNm }}
										</option>
									</select2>
								</div>
							</div>
						</div>
						<!-- 구분 -->
						<div>
							<div class="form-group">
								<label>{{ detailsFieldMap.searchType }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.aiDistinguishDvsnCd">
										<option value="">전체</option>
										<option v-for="item in options.aiDistinguishDvsnCdOption" :key="item.value" :value="item.value">
											{{ item.text }}
										</option>
									</select2>
								</div>
							</div>
						</div>
						<!-- 대상물명 -->
						<div>
							<div class="form-group">
								<label>{{ detailsFieldMap.searchObject }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.objectId">
										<option value="">전체</option>
										<option v-for="item in options.objectOptions" :key="item.objectId" :value="item.objectId">
											{{ item.objectNm }}
										</option>
									</select2>
								</div>
							</div>
						</div>

						<!-- 조치사항 -->
						<div>
							<div class="form-group">
								<label>{{ detailsFieldMap.searchTarget }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.targetId" :disabled="searchVM.objectId == ''">
										<option value="">전체</option>
										<option v-for="item in options.targetOptions" :key="item.targetId" :value="item.targetId">
											{{ item.targetNm }}
										</option>
									</select2>
								</div>
							</div>
						</div>

						<!-- 조회 버튼 -->
						<div>
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								조회
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div id="detailFrames" ref="detailFrames">
			<div class="header" v-if="frames.length">
				<span>{{ activeImgDate }}</span>
				<button v-if="!activeImgMisdect" class="btn btn-warning" @click="clickMisdect">오탐 ON</button>
				<button v-if="activeImgMisdect" class="btn btn-default" @click="clickDeMisdect">오탐 OFF</button>
			</div>
			<div class="img-wrapper">
				<i class="fa-solid fa-chevron-left" @click="clickPrevOneFrame" />
				<i class="fa-solid fa-chevron-right" @click="clickNextOneFrame" />
				<img v-show="activeImgSrc" class="active-img" :src="activeImgSrc" />
			</div>
			<div class="img-list-wrapper">
				<i class="fa-solid fa-chevron-left" @click="clickMoveManyFrame($event, -1)" />
				<i class="fa-solid fa-chevron-right" @click="clickMoveManyFrame" />
				<div class="img-list" ref="frameListRef">
					<div
						:class="`img-wrapper ${activeIndex === index ? 'active' : ''}`"
						v-for="(item, index) in frames"
						:key="index"
						@click="clickFrame(index)"
					>
						<img class="img" :data-test="item.frameImgSeq" :src="item.imgData || '/images/noimage.png'" />
					</div>
					<i class="fa-solid fa-spin fa-spinner" />
					<span class="end-content">마지막 프레임입니다</span>
				</div>
			</div>

			<div v-show="isEmpty" id="detailFrames-empty">검색 결과가 없습니다</div>
			<div v-show="isInit" id="detailFrames-empty"></div>
		</div>
	</div>
</template>

<script>
import Select2 from '@/components/Select2';
import moment from 'moment';
import apiIndex from '../../api/index';

const dangerOccurUrl = apiIndex.dangerOccur;
const aiDistinguishUrl = apiIndex.aiDistinguish;
let axiosExtention;

export default {
	components: {
		select2: Select2,
	},
	data: () => ({
		pageParam: null,
		searchVM: {},
		// 상세 select options
		detailsOptions: {},
		// 검색 select options
		searchOptions: {},
		// 적용된 검색조건
		searchState: {},
		options: {
			cctvOptions: [],
			siteOptions: [],
			aiDistinguishDvsnCdOption: [],
			objectOptions: [],
			targetOptions: [],
		},
		detailsFieldMap: {
			searchSite: '현장',
			searchCctv: 'CCTV',
			searchType: '구분',
			searchVideoDangerActnDvsnCd: '상태',
			searchObject: '대상물명',
			searchTarget: '조치사항명',
		},
		cctvChoiceMethod: 'cctvSelect',
		isDisabledSearchSite: true,
		page: 0,
		frames: [],
		activeIndex: 0,
		activeImgDate: '',
		activeImgSrc: '',
		activeImgMisdect: false,
		isEmpty: false,
		isInit: true,
	}),
	async created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('aiDistinguishPageParam'));

		if (!this.pageParam) location.href = apiIndex.mainUrl;

		this.searchVM = {
			dtm: moment().format('YYYY-MM-DD'),
			timeStat: moment().subtract(1, 'hours').format('HH:mm'),
			timeEnd: moment().format('HH:mm'),
			cctvInstallPlaceContSelect: '',
			cctvInstallPlaceContText: '',
			siteId: this.pageParam.loginUserSiteId,
			aiDistinguishDvsnCd: '',
			objectId: '',
			targetId: '',
			isCheckedCctvAll: false,
		};

		if (this.pageParam.cctvOptions && this.pageParam.cctvOptions.length) {
			this.searchVM.cctvInstallPlaceContSelect = this.pageParam.cctvOptions[0].cctvInstallPlaceCont;
		}

		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		this.options = {
			cctvOptions: this.pageParam.cctvOptions,
			siteOptions: this.pageParam.siteOptions,
			objectOptions: [...this.pageParam.objectOptions, ...this.pageParam.detectionObjectOptions],
			targetOptions: this.pageParam.targetOptions,
			aiDistinguishDvsnCdOption: commonCodeList.filter(item => item.grpCd == 'ai_distinguish_dvsn_cd'),
		};

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.isDisabledSearchSite = this.pageParam.loginUserRolecd === '00' ? false : true;
	},
	async mounted() {
		// 좌우 프레임 이동 키 이벤트 등록
		window.addEventListener('keydown', e => {
			e.stopPropagation();

			switch (e.code) {
				case 'ArrowLeft':
					return this.clickPrevOneFrame();
				case 'ArrowRight':
					return this.clickNextOneFrame();
			}
		});

		// 오른쪽 스크롤 위치가 전체의 90% 지점에 도달하면 데이터 추가 로드
		this.$refs.frameListRef.addEventListener('scroll', e => {
			const curPosition = e.target.scrollLeft + e.target.offsetWidth;
			if (curPosition / e.target.scrollWidth >= 0.9) {
				this.getFrames();
			}
		});

		// 스크롤 위치 초기화
		if ('scrollRestoration' in history) {
			history.scrollRestoration = 'manual';
		}
		window.scrollTo(0, 0);
		// await this.getFrames();
		// this.clickFrame(this.imgIndex || 0);

		// document.querySelector('body').classList.add('no-blockui');
	},
	watch: {
		'searchVM.siteId': {
			handler(value, oldValue) {
				if (oldValue !== undefined) {
					this.$axios
						.post(dangerOccurUrl.inqDangerOccurCctvInfo, { siteId: this.searchVM.siteId, isCheckedCctvAll: this.searchVM.isCheckedCctvAll })
						.then(
							function (r) {
								this.options.cctvOptions = r.data;
								this.searchVM.cctvInstallPlaceContSelect = '';
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		'searchVM.objectId': {
			handler(value, oldValue) {
				if (value != oldValue) {
					this.searchVM.targetId = '';
				}
				if (value != '' && value != null) {
					this.$axios
						.post(dangerOccurUrl.inqDangerOccurTargetInfo, { objectId: this.searchVM.objectId })
						.then(
							function (r) {
								this.options.targetOptions = r.data;

								if (this.pageParam.targetId) {
									this.searchVM.targetId = this.pageParam.targetId;
									this.search();
								}
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		'searchVM.aiDistinguishDvsnCd': {
			handler(value) {
				if (!value) {
					// 전체
					this.options.objectOptions = [...this.pageParam.objectOptions, ...this.pageParam.detectionObjectOptions];
				} else if (value === 'N') {
					// 단순인식
					this.options.objectOptions = this.pageParam.detectionObjectOptions;
				} else {
					// 위험/안전
					this.options.objectOptions = this.pageParam.objectOptions;
				}
			},
		},
		'searchVM.isCheckedCctvAll': {
			handler() {
				this.$axios
					.post(dangerOccurUrl.inqDangerOccurCctvInfo, { siteId: this.searchVM.siteId, isCheckedCctvAll: this.searchVM.isCheckedCctvAll })
					.then(
						function (r) {
							this.options.cctvOptions = r.data;
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			},
		},
		cctvChoiceMethod: {
			handler(value, oldValue) {
				if (value != oldValue) {
					if (this.pageParam.cctvOptions && this.pageParam.cctvOptions.length) {
						if (value === 'cctvSelect') {
							this.searchVM.cctvInstallPlaceContSelect = this.pageParam.cctvOptions[0].cctvInstallPlaceCont;
							this.searchVM.cctvInstallPlaceContText = '';
						} else {
							this.searchVM.cctvInstallPlaceContSelect = '';
							this.searchVM.cctvInstallPlaceContText = this.pageParam.cctvOptions[0].cctvInstallPlaceCont;
						}
					} else {
						this.searchVM.cctvInstallPlaceContSelect = '';
						this.searchVM.cctvInstallPlaceContText = '';
					}
				}
			},
		},
	},
	methods: {
		async search() {
			this.isInit = false;
			this.page = 0;
			this.activeIndex = 0;
			this.activeImgSrc = '';
			this.activeImgMisdect = false;
			this.$refs.frameListRef.classList.remove('end');
			this.frames = [];
			await this.getFrames();
			this.clickFrame(0);
		},
		//검색적용
		async getFrames() {
			const classList = this.$refs.frameListRef.classList;

			// 현재 데이터를 fetch 중이거나 마지막이면 동작 무시
			if (['loading-list', 'end'].some(className => classList.contains(className))) return;
			classList.add('loading-list');

			const pageSize = 30;
			const { data } = await this.$axios.post(aiDistinguishUrl.inqAiDistinguish + `?page=${this.page++}&pageSize=${pageSize}`, this.searchVM);

			if (data) {
				data.content.forEach(e => {
					e.imgData = `data:image/jpg;base64,${e.imgByte}`;
				});

				this.frames = [...this.frames, ...data.content];

				// 첫 로딩인데 프레임 데이터가 있으면..
				if (this.frames.length && this.page === 1) {
					this.activeImgSrc = `data:image/jpg;base64,${this.frames[0].imgByte}`;
					this.activeImgMisdect = this.frames[0].misdect;
					this.$nextTick(function () {
						window.scrollTo({ top: document.body.offsetHeight, behavior: 'smooth' });
					});
				}

				// 첫 로딩인데 프레임 데이터가 없으면..
				if (!this.frames.length && this.page === 1) {
					this.isEmpty = true;
				} else {
					this.isEmpty = false;
				}

				if (pageSize > data.content.length) {
					classList.add('end');
				}
			}

			classList.remove('loading-list');
		},
		// 이전 프레임 하나 이동 버튼 클릭
		clickPrevOneFrame() {
			const index = Math.max(0, this.activeIndex - 1);
			this.clickFrame(index);
		},
		// 다음 프레임 하나 이동 버튼 클릭
		clickNextOneFrame() {
			const index = Math.min(this.frames.length - 1, this.activeIndex + 1);
			this.clickFrame(index);
		},
		// 스크롤 거리 반환
		getScrollDistance() {
			const frameListRef = this.$refs.frameListRef;
			const frameWidth = frameListRef.children[0].offsetWidth;
			const frameWidthWithGap = frameWidth + 12; // 실제 프레임이 차지하고 있는 길이
			return frameListRef.offsetWidth - frameWidthWithGap - 50; // 스크롤 거리
		},
		// img list 현재 뷰포트에서 첫 번째 프레임 또는 마지막 프레임을 찾아서 클릭
		clickElemForMoveMany(direction = 1) {
			const frameListRef = this.$refs.frameListRef;
			const childrenList = frameListRef.children; // 현재 프레임 리스트
			const frameWidth = frameListRef.children[0].offsetWidth; // 프레임 하나의 가로 길이

			// 전체 프레임 리스트 루프
			for (let i = 0; i < childrenList.length; i++) {
				const left = frameListRef.scrollLeft; // img list의 현재 왼쪽 스크롤 위치
				const frameLeft = childrenList[i].offsetLeft; // 현재 루프 elem의 스크롤 left 위치

				// 왼쪽 이동이고, 스크롤이 맨 처음이면 첫 번째 클릭
				if (direction < 0 && left < frameWidth) {
					return this.clickFrame(0);
				}

				// 오른쪽 이동이고, 리스트가 마지막이고, 스크롤이 한 줄만 남았을 땐 마지막 요소를 클릭
				const totalScrollWidth = frameListRef.scrollWidth; // 전체 스크롤 길이
				const remainScrollWidth = totalScrollWidth - (left + frameWidth); // 남은 스크롤 길이 = 전체 - (현재 왼쪽 위치 + img list 길이)
				if (direction && frameListRef.classList.contains('end') && remainScrollWidth < frameListRef.offsetWidth) {
					return this.clickFrame(this.frames.length - 1);
				}

				// 현재 뷰포트 기준 가운데 요소 클릭
				const halfWidth = frameListRef.offsetWidth / 2 + left; // 현재 뷰포트 기준 가운데 left 위치
				if (frameLeft < halfWidth && frameLeft + frameWidth > halfWidth) {
					return this.clickFrame(i);
				}
			}
		},
		// 여러 프레임 이동 버튼 클릭
		// direction: 1=오른쪽(default), -1=왼쪽
		async clickMoveManyFrame(e, direction = 1) {
			const frameListRef = this.$refs.frameListRef;
			const frameListWidth = this.getScrollDistance();

			// 스크롤 중일 땐 무시
			if (frameListRef.classList.contains('busy')) return;

			// 스크롤 lock
			frameListRef.classList.add('busy');
			// 스크롤 실행
			frameListRef.scrollBy(frameListWidth * direction, 0);

			// 스크롤 후처리
			setTimeout(async () => {
				await this.clickElemForMoveMany(direction);
				frameListRef.classList.remove('busy');
			}, 600);
		},
		// 프레임 목록에서 프레임 클릭
		async clickFrame(index) {
			if (!this.frames.length) return;

			this.activeIndex = index;
			this.activeImgSrc = `data:image/jpg;base64,${this.frames[index].imgByte}`;
			this.activeImgMisdect = this.frames[index].misdect;

			// 주어진 문자열
			const inputString = this.frames[index].frameImgSeq;

			// 년, 월, 일, 시, 분, 초를 추출
			const year = inputString.slice(0, 4);
			const month = inputString.slice(4, 6);
			const day = inputString.slice(6, 8);
			const hour = inputString.slice(8, 10);
			const minute = inputString.slice(10, 12);
			const second = inputString.slice(12, 14);

			// "년-월-일 시:분:초" 형식으로 조합
			this.activeImgDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

			const targetElem = this.$refs.frameListRef.querySelector(`.img-wrapper:nth-child(${this.activeIndex + 1})`);

			if (targetElem) {
				targetElem.scrollIntoView({
					behavior: 'smooth',
					inline: 'center',
				});
			}
		},
		// 오탐 ON 버튼 클릭
		clickMisdect() {
			this.$axios
				.post(aiDistinguishUrl.aiDistinguishMisdetection, { frameImgSeq: this.frames[this.activeIndex].frameImgSeq })
				.then(() => {
					this.frames[this.activeIndex].misdect = true;
					this.activeImgMisdect = true;
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 오탐 OFF 버튼 클릭
		clickDeMisdect() {
			this.$axios
				.post(aiDistinguishUrl.aiDistinguishDeMisdetection, { frameImgSeq: this.frames[this.activeIndex].frameImgSeq })
				.then(() => {
					this.frames[this.activeIndex].misdect = false;
					this.activeImgMisdect = false;
				})
				.catch(axiosExtention.buildErrorHandler());
		},
	},
	destroyed() {
		document.querySelector('body').classList.remove('no-blockui');
		if ('scrollRestoration' in history) {
			history.scrollRestoration = 'auto';
		}
	},
};
</script>

<style>
*:focus {
	outline: none;
}

.select-wrapper > span {
	width: 190px !important;
	max-width: 190px;
}

#rootVM > .panel {
	display: flex;
	padding: 40px 20px;
}

#rootVM > .panel .panel-body {
	margin: auto;
}

#rootVM > .panel .panel-body form .row .form-group {
	display: flex;
	align-items: center;
	margin: 0;
}

#rootVM > .panel .panel-body .row {
	gap: 26px;
}

#rootVM > .panel .panel-body .row:first-child {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

#rootVM > .panel .panel-body .row .cctv-options {
	display: flex;
}

#rootVM > .panel .panel-body .row .cctv-options .checkbox {
	width: 50px;
	display: flex;
	align-items: center;
}

#rootVM > .panel .panel-body .row .cctv-options > div:last-child > span > div {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 33px;
}

#rootVM > .panel .panel-body .row .cctv-options > div:last-child > span > div input {
	margin: 0;
	margin-right: 4px;
}

#rootVM > .panel .panel-body .row .cctv-options > div:last-child > span > div label {
	margin: 0;
	margin-right: 10px;
}

#rootVM > .panel .panel-body .row:last-child {
	display: flex;
}

#rootVM > .panel .panel-body .row:last-child .select-wrapper > span {
	width: 150px !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-content: center;
	justify-content: center;
}

#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	max-width: 130px;
	margin-top: 5px;
	margin-right: 12px;
}

#rootVM > .panel .panel-body .row:last-child .form-group label {
	width: unset;
}

#rootVM #detailFrames {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	max-width: 1280px;
	height: 900px;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	margin-bottom: 20px;
	position: relative;
}

#rootVM #detailFrames .fa-solid {
	cursor: pointer;
}

#rootVM #detailFrames > .header {
	display: flex;
	font-size: 1.4em;
}

#rootVM #detailFrames > .header .btn {
	margin-left: auto;
	margin-bottom: 6px;
}

#rootVM #detailFrames > .img-wrapper {
	flex: 1;
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 720px;
	overflow: hidden;
}

#rootVM #detailFrames > .img-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

#rootVM #detailFrames > .img-wrapper .fa-solid {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	font-size: 1.6em;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	background: rgba(255, 255, 255, 0.6);
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

#rootVM #detailFrames > .img-wrapper .fa-chevron-left {
	left: 20px;
}

#rootVM #detailFrames > .img-wrapper .fa-chevron-right {
	right: 20px;
}

#rootVM #detailFrames .img-list-wrapper {
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 120px;
}

#rootVM #detailFrames .img-list-wrapper > .fa-solid {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	font-size: 1.4em;
}

#rootVM #detailFrames .img-list-wrapper .img-list {
	display: flex;
	align-items: center;
	gap: 12px;
	width: 100%;
	height: 100%;
	padding: 10px;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
}

#rootVM #detailFrames .img-list-wrapper .img-list .fa-solid {
	display: none;
	font-size: 1.6em;
	margin: auto;
}

#rootVM #detailFrames .img-list-wrapper > .fa-solid {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#rootVM #detailFrames .img-list-wrapper > .fa-chevron-left {
	left: 24px;
}

#rootVM #detailFrames .img-list-wrapper > .fa-chevron-right {
	right: 24px;
}

#rootVM #detailFrames .img-list-wrapper .img-list.loading-list .fa-solid {
	display: block;
}

#rootVM #detailFrames .img-list-wrapper .img-list .end-content {
	display: none;
	flex-shrink: 0;
	width: 100px;
	word-break: keep-all;
	text-align: center;
	line-height: 1.6em;
	color: #ddd;
	margin-right: 200px;
}

#rootVM #detailFrames .img-list-wrapper .img-list.end .end-content {
	display: block;
}

#rootVM #detailFrames .img-list-wrapper .img-list .img-wrapper {
	width: 120px;
	min-width: 120px;
	height: 100%;
	scroll-snap-align: center;
	cursor: pointer;
}

#rootVM #detailFrames .img-list-wrapper .img-list .img-wrapper.active {
	border: 4px solid blue;
}

#rootVM #detailFrames .img-list-wrapper .img-list .img-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#rootVM #detailFrames-empty {
	font-size: 1.4em;
	color: gray;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	z-index: 10;
	background: white;
	padding-top: 160px;
}
</style>
